exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-cancellation-policy-tsx": () => import("./../../../src/pages/cancellation-policy.tsx" /* webpackChunkName: "component---src-pages-cancellation-policy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-new-collections-tsx": () => import("./../../../src/pages/new-collections.tsx" /* webpackChunkName: "component---src-pages-new-collections-tsx" */),
  "component---src-pages-order-form-tsx": () => import("./../../../src/pages/order-form.tsx" /* webpackChunkName: "component---src-pages-order-form-tsx" */),
  "component---src-pages-photo-gallery-tsx": () => import("./../../../src/pages/photo-gallery.tsx" /* webpackChunkName: "component---src-pages-photo-gallery-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

